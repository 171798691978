export function productPageScripts() {

    // Custom Datepicker
    datePicker($(".delivery_date"));

    // Custom Select Boxes
    $('select.custom-select').each(function() {
        styleCustomSelect($(this));
    });

    $(document).on('click', '.qty-update', function (event) {
        event.preventDefault();
        quantities($(this))
    })

    $(document).on('click', ".js-handleBackupSuit", async function (event) {
        $('#loader').show();
        event.preventDefault();
        var backupSuits = JSON.parse($(this).attr('data-backup'))
        console.log(backupSuits);
        for (let backup in backupSuits) {
            if(backupSuits[backup] == 'no') {
                $('input[name="cart[' + backup + '][backup_suit]"').prop('checked', true)
                if($(this).hasClass('cart')) {
                    await handleBackupSuit($('input[name="cart[' + backup + '][backup_suit]"'), true);
                } else {
                    await handleBackupSuit($('input[name="cart[' + backup + '][backup_suit]"'), false);
                }
            }
        }
        if($(this).hasClass('cart')) {
            window.location.href = '/cart';      
        } else {
            await update_minicart(); // Refresh the cart to reflect the updated price
        }
        $('#loader').hide();
    });

    $(document).on('change', ".add-backup", async function () {
        $('#loader').show();
        // handleBackupSuit(selector, cartPage);
        await handleBackupSuit($(this));
        if($(this).hasClass('cart')) {
            await handleBackupSuit($(this), true);
            window.location.href = '/cart';      
        } else {
            await handleBackupSuit($(this), false);
            await update_minicart(); // Refresh the cart to reflect the updated price
        }
        $('#loader').hide();

    });

    $(document).on('click', '[name="add-to-cart"]', function() {
        if($('body').hasClass('woocommerce-cart')) {
            // customAddtoCart({atc_element}, {page_reference})
            customAddtoCart($(this), true)
        } else {
            customAddtoCart($(this), false)
        }
    });

    // Updating price of upsells on the basis of selection in main product
    $('form.addtocart-form').on('change', 'select[name="rental_duration"], input[name="backup_suit"]', function() {
        // handlePriceDisplay({selector_element}, {page_reference})
        handlePriceDisplay($(this))
    })

    // Product Page Specific Functions
    if($('body').hasClass('product-template-default')) {

        handleEntireOutfitPrice();

        // Updating Delivery Date of upsells
        $('form.addtocart-form').on('change', 'input[name="delivery_date"]', function() {
            handleUpsellDelivery($(this))
        })

        // Updating product attributes on upsells
        $('form.addtocart-form.ori-product').on('change', 'select[name^="size["]:not([name="size[pa_size-of-suit-trouser]"])', function() {
            handleUpsellAttributes($(this))
        })

        // Load storage data only if its edit product page
        if((location.search.split(name + '=')[1] || '').split('&')[0] != "" && $('body').hasClass('single-product')) {
            var cartForm = $('form.addtocart-form.ori-product')
            var key = $(cartForm).attr('id');
            setTimeout(function() {
                loadStorageData(key);
                $(cartForm).find('input, select').each(function(){
                    if($(this).attr('name').includes('delivery_date')) {
                        datePicker($(this));
                        handleUpsellDelivery($(this))
                    } else if(!$(this).attr('name').includes('pa_size-of-suit-trouser')) {
                        handleUpsellAttributes($(this))
                    } else {
                        handlePriceDisplay($(this), true);
                    }
                });
            }, 1000)
        }

        // Product zoom functionality
        if ($(window).width() > 768) {
            setTimeout(function() {
                productZoom();
            }, 1000)
        }
    }

    // Functions to be excluded form Cart or Checkout Pages (Minicart specific functions)
    if (!$('body').hasClass('woocommerce-cart') && !$('body').hasClass('woocommerce-checkout')) {
        // Update minicart according to current state
        update_minicart();
        
        // Remove item from cart
        $(document).on('click', '#minicart .remove', function(event) {
            event.preventDefault();
            console.log('removing item from cart...');
            customRemovefromCart($(this));
        });

        $(document).on('click', '.cart-open', function() {
            $('#minicart').show();
            $('#minicart').addClass('active')
        })

        $(document).on('click', '.cart-close', function() {
            $('#minicart').removeClass('active')
        })
    }
}

function productZoom() {
    $(".product-images .product-slider img").elevateZoom({
        zoomType: "inner",
        preloading: 0,
        zoomWindowFadeIn: 500,
        zoomWindowFadeOut: 750
    });
};

function handlePriceDisplay(currentSelector, updatePage = false) {
    var cartForm = $(currentSelector).closest('form.addtocart-form')
    var finalprice = parseFloat($(cartForm).find('.js-final-price').attr('data-p'));
    if(updatePage) {
        if($(currentSelector).attr('name') == 'backup_suit') {
            if ($(currentSelector).is(":checked")) {
                finalprice += parseFloat($(currentSelector).attr('data-p'));
            }
        } else if($(currentSelector).attr('name') == 'rental_duration') {
            if ($(currentSelector).val() == 14) {
                finalprice += parseFloat($(currentSelector).find('option[value="14"').attr('data-rate'));
            }
        }

    } else {
        if($(currentSelector).attr('name') == 'backup_suit') {
            if ($(currentSelector).is(":checked")) {
                finalprice += parseFloat($(currentSelector).attr('data-p'));
            } else {
                finalprice -= parseFloat($(currentSelector).attr('data-p'));
            }
        } else if($(currentSelector).attr('name') == 'rental_duration') {
            if ($(currentSelector).val() == 14) {
                finalprice += parseFloat($(currentSelector).find('option[value="14"').attr('data-rate'));
            } else {
                finalprice -= parseFloat($(currentSelector).find('option[value="14"').attr('data-rate'));
            }
        }
    }

    updateFinalPrice(finalprice, cartForm);

    if($(currentSelector).attr('name') == 'rental_duration' && $(cartForm).hasClass('ori-product')) {
        var upsells = $('form.addtocart-form.upsell-product')
        
        if(upsells) {
            var upsellsArray = Array.from(upsells);
            for (let upsell of upsellsArray) {
                var rental = $(upsell).find('select[name="rental_duration"]');
                if(rental) {
                    rental.val($(currentSelector).val());
                    handlePriceDisplay(rental)
                }
            }
        }
    }
    
    handleEntireOutfitPrice();
}

function handleUpsellDelivery(currentSelector) {
    var upsell = $('form.addtocart-form.upsell-product')
    if(upsell) {
        var upsellDelivery = upsell.find('input[name="delivery_date"]');
        if(upsellDelivery) {
            upsellDelivery.val($(currentSelector).val());
        }
    }
}

function handleUpsellAttributes(currentSelector) {
    var selectedAttr = $(currentSelector).val();
    var selectedSize = selectedAttr.split('|')[0];
    var upsells = $('form.addtocart-form.upsell-product')
    if(upsells) {
        upsells.each(function() {
            var upsellAttr = $(this).find('select[name^=size');
            if(upsellAttr) {
                $(upsellAttr).find('option').each(function() {
                    if ($(this).val().startsWith(selectedSize)) {
                        $(this).attr('selected', 'selected');
                        $(upsellAttr).val($(this).val());
                        return false; // Break the loop
                        // break;
                    }
                });
            }
        })
    }
}

function handleEntireOutfitPrice() {
    var entirePrice = parseFloat($('form.addtocart-form.ori-product .js-final-price').attr('data-p'));
    var upsells = $('form.addtocart-form.upsell-product')
    if(upsells) {
        var upsellsArray = Array.from(upsells);
        upsellsArray.forEach(elm => {
            $(elm).each(function() {
                entirePrice += parseFloat($(elm).find('.js-final-price').attr('data-p'))
            });
        });
    }
    updateFinalPrice(entirePrice, $('.js-entireOutfit-atc'))
}

function updateFinalPrice(finalprice, cartForm) {
    $(cartForm).find('.js-final-price').text(finalprice);
    $(cartForm).find('.js-final-price').attr('data-p', finalprice);
}

function loadStorageData(key) {
    if (Cookies.get(key.toString()) !== undefined ) {
        const savedData = JSON.parse(Cookies.get(key.toString())); // get and parse the saved data from localStorage

        $('#' + key).find('input, select').each(function(){
            var fieldName = $(this).attr('name');
            if (fieldName in savedData) {
                if(fieldName == 'backup_suit') {
                    if(savedData[fieldName] == 'added') {
                        $(this).prop('checked', true);	// for checkbox
                    }
                } else {
                    if($(this).val() != savedData[fieldName]) {
                        $(this).val( savedData[fieldName] );
                        $(this).find('option[value="' + savedData[fieldName] +'"]').attr('selected', 'selected')
                    }
                }
            }
            if (fieldName.startsWith("size[") || fieldName == 'rental_duration') {
                styleCustomSelect($(this));
            }
        });
    }
}

async function customAddtoCart($this, cartPage) {
    $("#loader").show();
    if ($this.hasClass('js-entireOutfit-atc')) {
        var validated = [];
        var originalcartForm = $this.closest('.product-hero').find('form.addtocart-form.ori-product')
        
        validated.push(validateForm(originalcartForm, cartPage));
        
        var upsellscartForms = $this.closest('.section-upsells').find('form.addtocart-form.upsell-product');
        if(upsellscartForms) {
            var upsellscartFormArray = Array.from(upsellscartForms);
            for (let upsellcartForm of upsellscartFormArray) {
                validated.push(validateForm($(upsellcartForm), cartPage));
            }
        }
        if(!validated.includes(false)) {

            if(upsellscartFormArray) {
                var cartForms = [originalcartForm].concat(upsellscartFormArray);
                await addToCartCall(cartForms);
                $this.html('Entire Outfit Added to the cart!');

            } else {
                await addToCartCall([originalcartForm]);
            }

            await update_minicart(true);
        } else {
            toastr.error('Please Select the required options!', 'Error !', { closeButton: true, progressBar: true, timeOut: 5000 });
        }

    } else {
        var validated = false;
        var cartForm = $this.closest('form.addtocart-form')
        validated = validateForm($this.closest('form.addtocart-form'), cartPage)
        if(validated) {
            await addToCartCall([cartForm])

            if(cartPage) {
                window.location.href = baseurl + '/cart';
            } else if(cartForm.hasClass('miniCartForm')) {
                await update_minicart(false);
            } else {
                await update_minicart(true);
            }
        } else {
            toastr.error('Please Select the required options!', 'Error !', { closeButton: true, progressBar: true, timeOut: 5000 });
        }
    }
    $("#loader").hide();
}

function validateForm(cartForm, cartPage) {
    var encounteredError = [];
    var upsellAtcForm = $(cartForm).hasClass('upsell-product');
    $(cartForm).find('input, select').each(function( index ) {
        if($(this).hasClass('addtocart-control')) {
            if(upsellAtcForm ) {
                var oriProductAttr;
                if($(this).attr('name').includes('shirt') || $(this).attr('name').includes('vest')) {
                    oriProductAttr = $('.ori-product').find('select[name^="size["]:not([name="size[pa_size-of-suit-trouser]"])');
                } else if ($(this).attr('name').includes('shoes')) {
                    oriProductAttr = '';
                } else {
                    oriProductAttr = $('.ori-product').find('[name=' + $(this).attr('name') + ']');
                }
            }
            if ($(this).prop('required') && !$(this).val()) {
                encounteredError.push(true);
                $(this).addClass('error-field')
                if (!$(this).next('.field-error').length) { 
                    $(this).after('<span class="field-error">Please Select the value</span>');
                }
                if(upsellAtcForm && oriProductAttr != '') {
                    oriProductAttr.addClass('error-field')
                    if (!oriProductAttr.next('.field-error').length) { 
                        oriProductAttr.after('<span class="field-error">Please Select the value</span>');
                    }
                }
                if(cartPage) {
                    $(cartForm).find('.product-info').slideDown();
                } else {
                    if(upsellAtcForm && $(cartForm).attr('data-cat').includes('shoe')) {
                        $(cartForm).find('.product-info').slideDown();
                        $('.ori-product').parents('.product-info').slideDown();
                    } else if($(cartForm).hasClass('ori-product')) {
                        $(cartForm).parents('.product-info').slideDown();
                    } else if($(cartForm).hasClass('miniCartForm')) {
                        $(cartForm).find('.product-info').slideDown();
                    } else {
                        $('.ori-product').parents('.product-info').slideDown();
                    }
                }
            } else {
                encounteredError.push(false);
                $(this).removeClass('error-field')
                if($(this).next('.field-error').length) {
                    $(this).next('.field-error').remove();
                }
                if(upsellAtcForm && oriProductAttr != '') {
                    oriProductAttr.removeClass('error-field')
                    if(oriProductAttr.next('.field-error').length) {
                        oriProductAttr.next('.field-error').remove();
                    }
                }
            }
        }
    });
    if(!encounteredError.includes(true)) {
        return true;
    }
    return false;
}

function addToCartCall(cartForms) {
    var form_data_array = [];
    cartForms.forEach(function(cartForm) {
        var formString = $(cartForm).find(':input').serialize();
        form_data_array.push(formString);
    })
    return $.ajax({
        url: ajaxurl,
        method: "post",
        dataType: "json",
        data: {
            action: "custom_add_to_cart",
            form_data_array,
        },
        success: function (response) {
            console.log(response);
            if (response.status) {
                cartForms.forEach(function(form) {
                    var key = $(form).attr('id');
                    var data = {};

                    $.each($(form).serializeArray(), function (index, field) {
                        if ($.inArray(field.name, ['cart_item_key', 'product_type', 'product_id', 'pkg_name']) < 0) {
                            if (field.name == 'backup_suit' && $('input[name=' + field.name + ']').is(":checked")) {
                                data[field.name] = 'added';
                            } else {
                                data[field.name] = field.value;
                            }
                        }
                    });

                    Cookies.set(key, JSON.stringify(data));

                    // Update button text for each form
                    $(form).find('[name="add-to-cart"]').html(response.message);
                    console.log(key);
                    console.log(Cookies.get(key));
                });
            } else {
                toastr.error(response.message, 'Error !', { closeButton: true, progressBar: true, timeOut: 5000 });
            }
        },
        error: function (data) {
            console.log('inside ajax error');
        }
    });
}

function updateCartCount() {
    $.ajax({
        url: ajaxurl,
        method: 'POST',
        data: {
            action: 'get_cart_item_count',
        },
        success: function(response) {
            console.log('cart count: ' + response);
            $('#cart-count').text(response);
            if(parseInt(response) > 0 ) {
                $('#cart-count').show();
            }
        },
        error: function (data) {
            console.log('inside ajax error');
        }
    });
}

function update_minicart(showCart = false) {
    console.log('update minicart in progress');
    return $.ajax({
        url: ajaxurl,
        method: 'POST',
        data: {
            action: 'load_cart_contents',
            _: new Date().getTime() // Append timestamp to prevent caching
        },
        success: function(response) {

            $('#minicart #minicart-content').html(response);
            updateCartCount();
            if(showCart) {
                $('#minicart').show();
                $('#minicart').addClass('active')
            }
            console.log('minicart updated');

            // date picker
            datePicker($(".minicart .delivery_date"));
            $('.minicart select.custom-select').each(function() {
                styleCustomSelect($(this));
            });
        }, error: function(data) {
            console.log('Inside Ajax Error!');
        }
    });
}

function quantities($this) {
    $('#loader').show();
    var elm = $this.parents('.quantity-box').find('input.qty_field');
    if ($this.hasClass('add') ) {
        $(elm).val(parseInt($(elm).val()) + 1);
    }

    if ($this.hasClass('sub') ) {
        $(elm).val(parseInt($(elm).val()) - 1);
    }

    if($this.hasClass('cart')) {
        $('[name="update_cart"]').prop("disabled", false);
        $('[name="update_cart"]').trigger('click');
        
    } else if($this.hasClass('mincart')) {
        const itemKey = $this.parents('.cart_item').data('cart-key');
        const quantity = $(elm).val();
    
        $.ajax({
            url: ajaxurl,
            method: 'POST',
            data: {
                action: 'update_cart_item_quantity',
                cart_item_key: itemKey,
                quantity: quantity
            },
            success: async function(response) {
                await update_minicart(); // Refresh the cart to reflect the updated quantity
                $('#loader').hide();
            },
            error: function (data) {
                console.log('inside ajax error');
            }
        });
    }

}

function customRemovefromCart($this) {
    $('#loader').show();
    const itemKey = $this.parents('.cart_item').data('cart-key');
    $.ajax({
        url: ajaxurl,
        method: 'POST',
        data: {
            action: 'update_cart_item_quantity',
            cart_item_key: itemKey,
            quantity: 0
        },
        success: async function(response) {
            await update_minicart(); // Refresh the cart to reflect the updated quantity
            $('#loader').hide();
        },
        error: function (data) {
            console.log('inside ajax error');
            $('#loader').hide();
        }
    });

}

function handleBackupSuit($this, cartPage) {
    var form_data = $("form.woocommerce-cart-form").serialize();
    return $.ajax({
        url: ajaxurl,
        method: "post",
        dataType: "json",
        data: {
            action: "custom_update_cart",
            form_data,
        },
        success: function (response) {
            if (response.status) {

                if(response.backup_suit_data) {
                    var key = response.backup_suit_data.local_storage_key;
                    if (Cookies.get(key.toString()) !== undefined) {
                        var keyData = JSON.parse(Cookies.get(key.toString()));
                        keyData['backup_suit'] = 'added';
                        Cookies.set(key, JSON.stringify(keyData));
                    }
                    $(document.body).trigger('wc_fragment_refresh');
                    if(cartPage) {
                        var subtotalElement = $this.parents('.cart_item').find('.js-subtotal');
                        var quantity = parseInt($this.parents('.cart_item').find('input.qty_field').val());
                        var subtotal = parseFloat($(subtotalElement).attr('data-p'))
                        if ($this.is(":checked")) {
                            subtotal += (parseFloat($this.attr('data-p')) * quantity);
                        } else {
                            subtotal -= (parseFloat($this.attr('data-p')) * quantity);
                        }
                        $(subtotalElement).html(subtotal)
                        $(subtotalElement).attr('data-p', subtotal);
                    }
                }
            }
        },
        error: function (data) {
            console.log('inside ajax error');
        }
    });
}

function datePicker($this) {
    $this.flatpickr({
        dateFormat: 'd-M-Y',
        minDate: "today",
        onOpen: function(selectedDates, dateStr, instance) {
            $('.flatpickr-calendar').append("<p>Pick a Delivery Date 2-5 days before your event</p>");
            $('.flatpickr-calendar').append("<div class='calender-legend'><div class='start'><span class='box'></span><span class='text'>At Your door<br />by 8pm</span></div><div class='end'><span class='box'></span><span class='text'>Drop in mail<br />by 5pm</span></div></div>");
        },
        // onMonthChange: function(selectedDates, dateStr, instance) {
        //     dateRange(instance);
        // },
        onChange: function(selectedDates, dateStr, instance) {
            dateRange(instance)
            
        },
        "disable": [
            function(date) {
                // return true to disable
                return (date.getDay() === 0 || date.getDay() === 6);
            }
        ]
    });
}

function dateRange(instance) {
    var pkg_id = $(instance.input).attr('data-pkg_id');
    var rental_duration = $("#rental_" + pkg_id).val();

    if(rental_duration) {
        rental_duration = parseInt(rental_duration);
        var daySpan = $(instance.daysContainer).find('.flatpickr-day.selected');
        daySpan.addClass('startRange');

        for (var i = 1; i < rental_duration; i++) {
            daySpan.removeClass('flatpickr-disabled');
            daySpan = daySpan.next();
            daySpan.addClass('selected');

            if (i == rental_duration - 1) {
                daySpan.addClass('endRange');
            }
        }
        instance.open();
    }
}

/* Reference: http://jsfiddle.net/BB3JK/47/ */
function styleCustomSelect($select) {
    var numberOfOptions = $select.children('option').length;
    if(!$select.hasClass('select-hidden')) {
        $select.addClass('select-hidden');
        $select.wrap('<div class="custom-select"></div>');
        $select.after('<div class="select-styled"></div>');

        var $styledSelect = $select.next('div.select-styled');
        $styledSelect.text($select.children('option').eq(0).text());

        var $list = $('<ul />', {
            'class': 'select-options'
        }).insertAfter($styledSelect);

        for (var i = 0; i < numberOfOptions; i++) {
            $('<li />', {
                text: $select.children('option').eq(i).text(),
                rel: $select.children('option').eq(i).val()
            }).appendTo($list);
        }
    } else {
        var $styledSelect = $select.next('div.select-styled');
        $styledSelect.text($select.children('option').eq(0).text());
        var $list = $select.next('ul.select-options')
    }

    for (var i = 0; i < numberOfOptions; i++) {
        if ($select.children('option').eq(i).is(':selected')){
            $('li[rel="' + $select.children('option').eq(i).val() + '"]').addClass('is-selected');
            $styledSelect.text($select.children('option').eq(i).text());
            break;
        }
    }

    $styledSelect.click(function(e) {
        e.stopPropagation();
        $('div.select-styled.active').removeClass('active').next('ul.select-options').hide();
        $(this).toggleClass('active').next('ul.select-options').toggle();
    });

    var $listItems = $list.children('li');
    $listItems.click(function(e) {
        e.stopPropagation();
        $styledSelect.text($(this).text()).removeClass('active');
        $list.find('li.is-selected').removeClass('is-selected');
        $list.find('li[rel="' + $(this).attr('rel') + '"]').addClass('is-selected');
        $list.hide();
        $select.val($(this).attr('rel'));
        $select.trigger('change');
    });

    $(document).click(function() {
        $styledSelect.removeClass('active');
        $list.hide();
    });
}