export function mobileHeader() {
    var headerItems = document.querySelectorAll('.menu-item-has-children') 

    if( !headerItems.length ) return;
    
    for(var i = 0; i < headerItems.length; i++) {
        headerItems[i].addEventListener('click', function(e) { 
            if(e.target.nodeName != 'A'){
                this.classList.toggle('active');
            }
         });
    }
}

export function hamburger() {
    $('.site-header__hamburger').on('click', function(){
        $('body').toggleClass('menu-open');
    });
}

export function stickyHeader() {
    $(window).on("scroll load", function () {
        if ($(window).scrollTop() >= 50) {
            $('.site-header').addClass('scrolled');
        } else {
            $('.site-header').removeClass('scrolled');
        }
    });
}

export function megaMenu() {
    if ($(window).width() > 992) {
        $(document).on('mouseover', '.menu-item-has-children', function() {
            if($(this).parents('.menu-item-has-children').length != 0) {
                $(this).closest('.sub-menu').find('.menu-item-has-children').removeClass('active')
                $(this).addClass('active');
            } else {
                $(this).addClass('active');
            }
        })
        $(document).on('mouseleave', '.menu-item-has-children', function() {
            if($(this).parents('.menu-item-has-children').length != 0) {
                console.log('im submenu');
                var $this = $(this);
                $(this).on('mouseleave', 'ul.sub-menu', function() {
                    $(this).removeClass('active');
                    $this.removeClass('active')
                })            
            } else {
                $(this).removeClass('active');
            }
    
        })
    
        $(document).on('mouseover', '.menu-item-has-children ul.sub-menu', function() {
            $(this).closest('.menu-item-has-children').addClass('active-submenu');
        })
        $(document).on('mouseleave', '.menu-item-has-children ul.sub-menu', function() {
            $(this).parents('.menu-item-has-children').removeClass('active-submenu');
        })
    }
}