export function filters() {
    $('.js-filter').click(function(evt) {
        evt.preventDefault();
        let target = $(this).data('slug');

        // Clear filter, show everything
        if($(this).hasClass('js-clear')) {
            resetFilters();
        } else {
            // 1st filter activated
            if($('.js-filter.active').length == 0) {
                hideAllProducts();
                $(this).addClass('active');
                showProduct(target)

            } else {
                // more than 1 filters active
                if(!$(this).hasClass('active')) {
                    $(this).addClass('active');
                    showProduct(target)

                } else {

                    // Filter Deactivation
                    $(this).removeClass('active');
                    if($('.js-filter.active').length > 0) {

                        // only 1 of the filters deactivated
                        hideProduct(target);
                    } else {

                        // All filters deactivated
                        resetFilters();
                    }
                }
            }
        }

        // Reset product count
        $('.js-product-count').text($('.product-card.active').length);

    })

    $(window).on('resize', function() {
        if($(window).width() < 768) {
            // Reset filters
            resetFilters()
        } 
    })

    function resetFilters() {
        $('.js-filter').removeClass('active')
        $('.product-card').fadeIn(700);
        $('.product-card').addClass('active');
        $('.js-product-count').text($('.product-card.active').length);
    }
    function hideAllProducts() {
        $('.product-card').fadeOut(500);
        $('.product-card').removeClass('active');
    }

    function showProduct(target) {
        $('.product-card.' + target).fadeIn(700);
        $('.product-card.' + target).addClass('active');
    }
    function hideProduct(target) {
        $('.product-card.' + target).fadeOut(500);
        $('.product-card.' + target).removeClass('active');
    }
}